import React from "react";
import Layout from "../containers/Layout";
import {graphql, HeadFC} from "gatsby";
import Seo from "../containers/Seo";

function TermsPage(): JSX.Element {
  return (
    <Layout>
      <main>
        <div className="hero-unit">
          <div className="container">
            <h1>利用規約</h1>
          </div>
          <div className="zag"/>
        </div>

        <div className="container">
          <div className="warning-bg">
            <p>
              ワキ動画買取所（以下「当センター」といいます。）が実施するモデル募集キャンペーン（以下、「本キャンペーン」といいます。）へ応募を希望される方は、
              この利用規約（以下、「本規約」といいます。）をよくお読みの上、同意される場合のみ応募してください。
              なお、本キャンペーンに応募された方は、本規約に同意したものとみなします。
            </p>

            <h2>第1条（適用）</h2>
            <p>
              本規約は、本キャンペーンの応募者（以下、「応募者」といいます。）と当センターとの間の本キャンペーンに関わる一切の関係に適用されます。
            </p>

            <h2>第2条（個人情報の提供）</h2>
            <p>
              本キャンペーンへの応募にあたり応募者のプロフィールや写真等を正確に提供して頂く必要がある場合、提供された内容の全部又は一部について、
              虚偽、誤記又は記載漏れがあった場合には、本キャンペーンの対象外とさせて頂くことがあります。
            </p>

            <h2>第3条（応募者のデータファイル）</h2>
            <ol>
              <li>応募者は、当センター及び当センターに対して、自らが本キャンペーンを通じてアップロードしたデータファイルについて使用、複製、配布、他言語への翻訳を含む派生著作物の作成、表示及び実行に関する全世界的、無償、非独占的、サブライセンス可能かつ譲渡可能なライセンスを付与し、著作者人格権を行使しないことに同意したことになります。</li>
              <li>ただし、応募の際に販売・公開の禁止を指定したものについて、当センターは当該行為を行わないものとします。</li>
            </ol>

            <h2>第4条（禁止事項）</h2>
            <p>
              本キャンペーンへの応募に際しては、以下の行為を禁止します。当センターは、応募者が以下に該当する行為を行ったと判断した場合には、事前に通知することなく、本キャンペーンから当該応募者を対象外としたり、又はその投稿データを一次的に非掲載とし若しくは削除することがあります。
            </p>

            <ol>
              <li>本キャンペーンの運営を妨げる行為</li>
              <li>本規約に違反する行為</li>
              <li>他人に迷惑、不利益、損害、または不快感を与える行為</li>
              <li>他人を誹謗中傷し、またはその名誉もしくは信用を害する行為</li>
              <li>他人の著作権その他の知的財産権を侵害する行為</li>
              <li>他人の財産、プライバシーもしくは肖像権を侵害する行為</li>
              <li>営利を目的とした情報提供、広告宣伝もしくは勧誘行為</li>
              <li>公序良俗に反する行為</li>
              <li>応募に使用するSNSの利用規約その他各規定に違反する行為</li>
              <li>その他当センターが相当でないと判断する行為</li>
            </ol>

            <h2>第5条（本サービスの提供の停止等）</h2>
            <p>
              当センターは、以下のいずれかの事由があると判断した場合、応募者に事前に通知することなく
              本キャンペーンを中断または中止することがあります。
            </p>
            <ol>
              <li>本サービス実施にかかるコンピュータシステムの保守点検または更新を行う場合</li>
              <li>地震、落雷、火災、停電または天災などの不可抗力により、本キャンペーンの実施が困難となった場合</li>
              <li>コンピュータまたは通信回線等が事故により停止した場合</li>
              <li>応募に使用するSNSのサービスが中断または中止した場合</li>
              <li>その他、当センターが本キャンペーンの実施を困難と判断した場合</li>
            </ol>
            <p>
              当センターは、前項による本キャンペーンの中断または中止により、応募者が被った不利益または損害について、一切の責任を負わないものとします。
            </p>

            <h2>第6条（免責）</h2>
            <ul>
              <li>当センターは、本キャンペーンに関連して生じた応募者の損害について、当センターの故意または重大
                な過失がない限り、一切の責任を負わないものとします。</li>
              <li>
                当センターは、本キャンペーンに関連して生じた応募者間または応募者と第三者の間におけるト
                ラブルについて一切の責任を負わないものとします。
              </li>
            </ul>

            <h2>第7条（キャンペーン内容の変更等）</h2>
            <p>
              当センターは、応募者に予告することなく、本キャンペーンの内容の一部または全部を変更し、もし
              くは本サービスの提供を中止することがあります。当センターは、これによって応募者に生じた損害
              について一切の責任を負いません。
            </p>

            <h2>第8条（本規約の変更）</h2>
            <p>
              当センターは、必要と判断した場合には、応募者に予告することなく、本規約を変更することができ
              るものとします。
            </p>

            <h2>第9条（個人情報の取扱い）</h2>
            <p>
              本キャンペーンにあたってご提供いただいた個人情報は、以下の目的でのみ使用いたしま
              す。
            </p>

            <ol>
              <li>査定結果のお知らせと応募者への報酬の支払い</li>
              <li>上記に関する連絡</li>
              <li>商品やサービスの参考とするための個人を特定しない統計情報の形での利用</li>
            </ol>

            <p>
              本キャンペーンにあたってご提供いただいた個人情報は、応募者ご本人の同意がある場合
              を除いて、第三者に提供されることはありません。ただし、法令の定めがある場合等、当
              社「プライバシーポリシー」にて定める事由がある場合を除きます。詳しくは当センター「プラ
              イバシーポリシー」をご覧ください。
            </p>

            <h2>第10条（通知または連絡）</h2>
            <p>
              当センターから応募者に対する通知または連絡は、当センターの定める方法で行うものとし、応募者のメー
              ルアドレスの不備等により通知または連絡が到達しなかった場合には、本キャンペーンの対象
              外とさせて頂くことがあります。
            </p>

            <p>
              以上
            </p>

          </div>
        </div>
      </main>
    </Layout>
  );
}

export const query = graphql`
  query TermsQuery {
    site {
      siteMetadata {
        title
        description
        twitter
        siteUrl
      }
    }
  }
`

export const Head: HeadFC = ({data}: {data: any}) => (
  <Seo data={data}>
    <title id="title">利用規約 | {data.site.siteMetadata.title}</title>
    <meta id="description" name="description" content={"当サイトをご利用いただくお客様は必ずこちらの利用規約をご覧ください。"} />
  </Seo>
);

export default TermsPage;